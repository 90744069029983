import {Component, OnInit, ChangeDetectorRef, ViewChild, Inject, OnDestroy} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BookingsService} from '../../_services/bookings.service';
import {Booking} from '../../_models/booking';
import {UploadedPhotosService} from '../../_services/uploaded-photos.service';
import {UploadedPhoto} from '../../_models/uploaded-photo';
import {Subject, Subscription} from 'rxjs';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {DialogPhotosComponent} from './dialog-photos/dialog-photos.component';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DragulaService} from 'ng2-dragula';
import {UsersService} from '../../_services/users.service';


@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})

export class PhotosComponent implements OnInit, OnDestroy {

  isAdmin = false;
  downloadUrl = '';

  photos: UploadedPhoto[];
  userToken;

  booking: Booking;
  photoselected = [];

  pondOptions = {};

  pondFiles = [
    // 'index.html'
  ];

  files_pending_upload = 0;

  refresh: Subject<any> = new Subject();

  @ViewChild('myPond') myPond: any;

  subs = new Subscription();

  constructor(private bookingsService: BookingsService,
              private usersService: UsersService,
              private uploadedPhotosService: UploadedPhotosService,
              public dialog: MatDialog,
              private dragulaService: DragulaService) {

    dragulaService.createGroup('DRAGULA_PHOTOS', {
      accepts: (el, target, source, sibling) => {
        return el.className.includes('sortable');
      }
    });

  }

  ngOnInit() {

    this.isAdmin = this.usersService.currentUserIsAdmin();


    this.subs.add(this.dragulaService.drop()
      .subscribe(({name, el, target, source, sibling}) => {
          // ...
          // [].slice.call(el.parentElement.children).forEach(function (child) {
          const children = Array.prototype.slice.call(target.children);
          const sorted = [];
          children.forEach(function (element) {
            const photo_id = element.id.replace(/\D/g, '');
            if (photo_id) {
              sorted.push(photo_id);
            }

          });
          // console.log(sorted.join(','));
          this.updateSortOrder(sorted.join(','));
          // console.log({name, el, target, source, sibling});
        }
      ));

    if (typeof this.bookingsService.currentBooking !== 'undefined') {
      this.init();

    }
    this.bookingsService.bookingLoaded.subscribe(res => {
      this.init();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.dragulaService.destroy('DRAGULA_PHOTOS');
  }


  init() {
    this.booking = this.bookingsService.currentBooking;

    this.uploadedPhotosService.getPhotos(this.booking.id);

    this.uploadedPhotosService.resultsLoaded.subscribe(res => {
      this.photos = res;
      console.log('PHOTOS');
      console.log(res);
    });

    if (localStorage.getItem('currentUser') != null) {
      this.userToken = JSON.parse(localStorage.getItem('currentUser')).token;
    } else {
      this.userToken = JSON.parse(sessionStorage.getItem('currentUser')).token;
    }


    // Some help from https://github.com/meateam/seal/blob/3e37462b6c2374fc718bd20d739a75b051cadccd/client/src/app/upload/upload.component.ts
    this.pondOptions = {
      class: 'file-pond',
      allowImageValidateSize: true,
      imageValidateSizeMinWidth: 1200,
      imageValidateSizeMinHeight: 650,
      maxFiles: 100,
      maxParallelUploads: 1,
      allowMultiple: true,
      labelIdle: 'Click to upload, or drag and drop your files here!',
      acceptedFileTypes: 'image/*',
      server: {
        url: `${environment.apiEndPoint}`,
        timeout: 300000,
        process: {
          url: `photos/${this.booking.id}`,
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + this.userToken
          },
          withCredentials: false
        },
        instantUpload: false,
        dropOnElement: true,
        dropOnPage: true,
        imagePreviewHeight: 250,
        imagePreviewWidth: 250,
        required: true,
        allowImagePreview: true,
        allowRevert: false
      }
    };


    this.downloadUrl = `${environment.managerUrl}bookings/${this.booking.id}/downloadPhotos`;

  }

  pondHandleUpload(event: any) {
    const file: any = event.file;
    // console.log(`Event is.. `);
    // console.log(file);

    this.myPond.removeFile(file);

    this.files_pending_upload--;
    const counter = this.files_pending_upload;
    console.log('Removed ', counter);
    if (counter === 0) {
      console.log('Removed (!!) ', counter);
      this.uploadedPhotosService.getPhotos(this.booking.id);
    }
  }


  pondHandleInit() {
  }

  pondHandleAddFile(event: any) {
    this.files_pending_upload++;
    const counter = this.files_pending_upload;
    console.log('Added ', counter);
  }


  filepondOnUpload(response) {
    console.log('`````````````````', this.booking.id);
    this.uploadedPhotosService.getPhotos(this.booking.id);

  }


  filepondOnError(response) {
    console.log(`onerror`, response);
    return response.data;
  }


  deleteSelected() {
    const selecteddata = {};
    for (let i = 0; i < this.photoselected.length; i++) {
      selecteddata[i + 1] = this.photoselected[i];
      const result = this.uploadedPhotosService.deletePhotos(this.photoselected[i]).subscribe(res => {

        this.uploadedPhotosService.getPhotos(this.booking.id);
      });
    }
    (<HTMLInputElement>document.getElementById('localtrig')).click();
  }

  makeFeatured() {

    const selecteddata = {};
    for (let i = 0; i < this.photoselected.length; i++) {
      selecteddata[i + 1] = this.photoselected[i];
      const result = this.uploadedPhotosService.featurePhotos(this.booking.id, this.photoselected[i]).subscribe(res => {

        this.uploadedPhotosService.getPhotos(this.booking.id);
      });
    }
    (<HTMLInputElement>document.getElementById('localtrig')).click();

    this.photoselected = [];

  }

  updateSortOrder(ids) {
    const result = this.uploadedPhotosService.updateSortOrder(this.booking.id, ids).subscribe(res => {
      // Do nothing...
    });
  }

  onChange(id: number, isChecked: boolean) {

    if (isChecked) {
      this.photoselected.push(id);
    } else {
      const index = this.photoselected.indexOf(id);
      this.photoselected.splice(index, 1);
    }
  }

  openDialog(murl: string) {

    const dialogRef = this.dialog.open(DialogPhotosComponent, {
      width: '700px',
      height: 'auto',
      data: {photourl: murl}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog closed: ${result}`);
    });
  }

  cleanPath(path: string) {
    return environment.managerUrl + 'storage/' + path.replace('public/', '');
  }

}
